<template>
  <QInput
    :ref="`TextInput${$_.get(step, 'field') ? `.${$_.get(step, 'field')}` : ''}`"
    outlined
    :dense="dense"
    :label="computedLabel"
    :maxlength="20"
    :model-value="inpNumber"
    @update:model-value="numberChanged"
    :readonly="readonly"
    class="vue3-q-tel-input no-inherit-feedback"
    :rules="[isValidNumber]"
    debounce="500"
    bottom-slots
  >
    <template #prepend>
      <QIcon v-if="showIcon" name="uil:phone-alt" />
      <CountrySelect
        :searchLabel="$t({ id: searchLabel })"
        @countryChanged="countryChanged"
        v-model:country="country"
        class="
          q-ml-sm
          no-border-field-before no-padding-field
          font-reduced-input-adon
        "
      />
    </template>
  </QInput>
</template>

<script>
// Inpired by https://github.com/tohagan/quasar-q-phone-input
import { parsePhoneNumber, AsYouType } from 'libphonenumber-js'
import CountrySelect from './CountrySelect.vue'
import { getCountryByCode } from './countries'

export default {
  components: { CountrySelect },
  props: {
    searchLabel: {
      type: String,
      default: 'prompt.search_button'
    },
    defaultCountry: {
      type: String,
      default: 'FR'
    },

    // Forms
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null
    },

    // Styling on the fly
    dense: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: false
    },

  },
  data() {
    return {
      country: getCountryByCode('FR'),
      inpNumber: '',
      phone: '',
      valid: false, // boolean | string
    }
  },
  computed: {
    defaults () {
      const cty = this.country
      return {
        defaultCountry: cty.iso2,
        defaultCallingCode: cty.dialCode,
      }
    },
    computedLabel () {
      const l = this.$_.get(this.step, 'label', this.$_.get(this.step, 'menuLabel', 'Numéro de téléphone *'))
      return l ? this.translationExists(l) ? this.$t({ id: l }) : l : false
    }
  },
  created () {
    this.$nextTick(() => {
      if (!this.$_.get(this.formObject, this.step.field) && this.$_.get(this.step, 'copyFromObject')) {
        this.inpNumber = this.$_.get(this.step, `copyFromObject.${this.step.field}`)
      } else this.inpNumber = this.$_.get(this.formObject, this.step.field)
      this.numberChanged(this.inpNumber)
      this.stepResult()
    })
  },
  mounted () {
    const cty = getCountryByCode(this.defaultCountry)
    if (!cty) throw new Error(`${this.defaultCountry}: Code pays invalide`)
    this.country = cty
    if (!this.light) this.$refs[`TextInput${this.$_.get(this.step, 'field') ? `.${this.$_.get(this.step, 'field')}` : ''}`].focus()
  },
  methods: {
    isValidNumber (val) {
      try {
        if (!val) return !this.step.optional ? 'Ce champ est requis' : true
        const phone = parsePhoneNumber(val, this.defaults)
        if (!phone || !phone.isValid()) return 'Le numéro de téléphone est incomplet ou incorrect'

        const phType = phone.getType()
        if (
          phType &&
          !['FIXED_LINE_OR_MOBILE', 'FIXED_LINE', 'MOBILE', 'VOIP'].includes(phType)
        ) {
          return 'Numéro de téléphone portable ou fixe requis'
        }

        return true
      } catch (e) {
        return 'Le numéro de téléphone est incomplet ou incorrect'
      }
    },
    countryChanged () {
      this.numberChanged(this.inpNumber)
    },
    numberChanged (newNumber) {
      newNumber = newNumber?.toString().trim() || ''
      const countryCode = this.country.iso2
      const asYouType = new AsYouType(countryCode)

      switch (countryCode) {
        case 'AU':
          if (/^[234578]$/.test(newNumber)) newNumber = '0' + newNumber
        case 'NZ':
          if (/^[234679]$/.test(newNumber)) newNumber = '0' + newNumber
      }

      this.inpNumber = newNumber = asYouType.input(newNumber)

      const newCountryCode = asYouType.getCountry()
      const newCountry = newCountryCode && getCountryByCode(newCountryCode)
      if (newCountry && this.country.dialCode !== newCountry.dialCode) {
        this.country = newCountry
      }

      const valid = this.isValidNumber(newNumber)
      if (valid === true) {
        const { number } = asYouType.getNumber()
        this.phone = number
        this.valid = true
      } else {
        this.phone = undefined
        this.valid = valid
      }
      this.stepResult()
    },

    stepResult () {
      const value = {}
      this.$_.set(value, this.step.field, this.phone)
      const canGoNext = (this.$_.get(this.phone, 'length', 0) > 0 && this.valid === true) || this.$_.get(this.step, 'optional', false)
      this.$emit('stepResult', { canGoNext, value })
    },
  },
}
</script>