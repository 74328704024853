<template>
  <QSelect
    ref="selectCountry"
    hide-bottom-space
    hide-dropdown-icon
    borderless
    dense
    standout="bg-grey-3"
    :model-value="country"
    @update:model-value="countryChanged"
    @popup-show="onShow"
    :options="countryOptions"
    virtual-scroll-slice-size="9999"
    class="no-inherit-feedback no-feedback v3-q-tel-input--country"
    :menu-offset="[12, 0]"
    v-bind="props"
    style="width: 70px"
  >
    <template v-slot:option="scope">
      <div
        class="flex items-center q-pa-xs mdi-border-bottom no-wrap"
        v-bind="scope.itemProps"
      >
        <span :class="['v3q_tel__flag', scope.opt.iso2.toLowerCase()]"></span>
        <span class="q-ml-sm text-no-wrap">(+{{ scope.opt.dialCode }})</span>
        <span class="q-ml-sm text-no-wrap ellipsis">{{ scope.opt.name }}</span>
      </div>
      <QSeparator />
    </template>
    <template v-slot:selected-item="scope">
      <div
        v-if="scope.opt"
        class="q-pa-none ellipsis"
        style="min-height: unset"
      >
        <div class="flex items-center no-wrap">
          <span
            :class="['v3q_tel__flag q-mr-sm', scope.opt.iso2.toLowerCase()]"
          ></span>
          <span
            class="ellipsis text-no-wrap"
            v-html="`+${scope.opt.dialCode}`"
          ></span>
        </div>
      </div>
    </template>
    <template v-slot:no-option>
      <!-- Same as v-slot:before-options -->
      <QInput
        class="sticky-input z-top v3-q-tel--country-selector q-pa-sm bg-white"
        ref="search"
        :label="searchLabel"
        v-model="searchText"
        @update:model-value="performSearch"
        dense
        outlined
      >
        <template v-slot:prepend>
          <QIcon name="search" />
        </template>
      </QInput>

      <div class="q-pa-sm">None match</div>
    </template>
    <template v-slot:before-options>
      <!-- Same as v-slot:before-no-option -->
      <QInput
        class="sticky-input z-top v3-q-tel--country-selector q-pa-sm bg-white"
        ref="search"
        :label="searchLabel"
        v-model="searchText"
        @update:model-value="performSearch"
        dense
        outlined
      >
        <template v-slot:prepend>
          <QIcon name="search" />
        </template>
      </QInput>
    </template>
  </QSelect>
</template>

<script>
import { filterCountries, countries } from './countries'

export default {
  name: 'CountrySelect',
  props: {
    country: {
      type: Object,
      required: true
    },
    searchLabel: {
      type: String,
      default: 'Search'
    }
  },
  data () {
    return {
      selectCountry: null,
      search: null,
      searchText: '',
      countryOptions: [...countries],
      prevCountry: null
    }
  },
  mounted () {
    this.prevCountry = this.country
  },
  methods: {
    performSearch () {
      const needle = this.searchText.toLowerCase().trim()
      this.countryOptions =
        needle === '' ? [...countries] : filterCountries(needle)
    },
    async onShow () {
      this.searchText = ''
      this.performSearch()
      this.$nextTick(() => {
        this.search?.focus()
      })
    },
    countryChanged (val) {
      this.$emit('update:country', val)
      this.$emit('countryChanged', val, this.prevCountry)
      this.prevCountry = val
    }
  },
}
</script>

<style lang="css">
@import './flags.css';
 .sticky-input {
	 position: -webkit-sticky;
	/* Safari */
	 position: sticky;
	 top: 0;
}
 .q-field--outlined .v3-q-tel-input--country .q-field__control:after {
	 border: none !important;
}
 .v3-q-tel-input--country .q-field__control {
	 background: none !important;
}
 .v3-q-tel-input--country .q-field__control .q-field__native {
	 padding-top: 2px;
}
 .v3-q-tel-input--country .q-field__control::before {
	 display: none !important;
}
 .v3-q-tel-input--country .q-field__input {
	 outline: none !important;
	 border: none !important;
}
 .v3-q-tel--country-selector {
	 position: sticky !important;
	 bottom: 0 !important;
	 left: 0 !important;
	 right: 0 !important;
}

</style>