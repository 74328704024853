<template>
  <QCard
    v-if="item"
    bordered
    :class="`hc-shadow fit bg-grey-1 br-25 text-left ${cptContext.current} ${cptContext.recommended}`"
  >
    <component
      :is="horizontal ? 'QCardSection' : 'div'"
      horizontal
    >
      <!-- Branding -->
      <QCardSection class="bg-white br-25">
        <div
          v-if="cptContext.badge"
          class="absolute-top-right text-center"
        >
          <QBadge
            class="q-pa-sm text-weight-bold text-dark br-25"
            :color="item.fields.color"
            :label="$t({ id: `component.cards.plan_card.${cptContext.badge}` })"
          />
        </div>
        <div class="flex items-center no-wrap">
          <QImg
            v-if="$_.get(item, 'fields.product.images[0]', false)"
            :src="cdnImg(`platform/product/happycab-${item.name}.svg`)"
            loading="lazy"
            alt="i"
            class="hc-round"
            style="width:70px; height: 70px"
          />
          <div :class="`text-h5 q-ml-md text-${$_.get(item, 'fields.color', 'primary')}`">
            {{ item.fields.label }}
          </div>
        </div>
        <div class="text-caption text-italic text-left text-grey-6 q-mt-sm">
          {{ $_.get(item, 'fields.context_phrase', '') }}
        </div>

        <!-- Pricing and CTA -->
        <div class="flex items-center q-mt-md">
          <div
            v-if="$_.get(item, 'metadata.hidePrice', false)"
            class="text-h6"
          >
            {{ $t({ id: 'shop.cta.quoted_price' }) }}
          </div>
          <template v-else>
            <AppContent
              path="number.price"
              class="text-h6"
              :options="{ price: item.fields.prices.find(p => p.id === (priceId || item.fields.product.default_price)).unit_amount / 100, tax: 'without' }"
            />
            <div
              v-if="$_.get(item, 'fields.product.unit_label', false)"
              class="text-subtitle2 text-weight-regular text-grey-5 q-ml-sm"
            >
              <span v-if="$_.get($_.get(item, 'fields.prices', []).find(p => p.id === (priceId || item.fields.product.default_price)), 'unit_amount', 0) > 0">
                /
              </span>
              <span>{{ item.fields.product.unit_label }}</span>
            </div>
          </template>
        </div>
        <HCBigButton
          v-if="showCta"
          class="full-width q-mt-md text-black"
          color="grey-2"
          :label="$t({ id: $_.get(item, 'fields.cta_label', false) ? `shop.cta.${item.fields.cta_label}` : 'prompt.select' })"
          @click="selectItem(item)"
        />
      </QCardSection>

      <!-- Features -->
      <QCardSection>
        <QList>
          <QItem
            v-if="item.fields.featuresInclude && compact"
            dense
          >
            <AppContent
              class="text-italic text-weight-medium"
              :path="`shop.features.v2.${item.fields.featuresInclude}`"
            />
          </QItem>
          <QItem
            v-for="feature in computedFeaturesList"
            :key="feature"
            class="q-px-none"
          >
            <QItemSection
              side
              class="q-pr-sm"
            >
              <QIcon
                size="sm"
                class="q-mt-sm"
                :color="feature.included ? 'positive' : 'grey-5'"
                :name="`uil:${feature.included ? 'check' : 'times'}`"
              />
            </QItemSection>
            <QItemSection>
              <QItemLabel>
                <AppContent :path="`shop.features.v2.${feature.title}`" />
              </QItemLabel>
              <QItemLabel
                v-if="feature.subtitle"
                caption
              >
                <AppContent :path="`shop.features.v2.${feature.subtitle}`" />
              </QItemLabel>
            </QItemSection>
          </QItem>
        </QList>
      </QCardSection>
    </component>
  </QCard>
</template>

<script>
import { base64Encoder } from 'hc-core/composables/routing.js'
import { getCurrentUserId } from 'hc-core/composables/auth.js'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    priceId: {
      type: String,
      default: null
    },
    compact: {
      type: Boolean,
      default: true
    },
    showCta: {
      type: Boolean,
      default: false
    },
    showCurrent: {
      type: Boolean,
      default: false
    },
    showRecommended: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false
    },
  },
  emits: ['selectItem'],
  computed: {
    allFeatures () {
      const r = [
        'company_page',
        'offer_publication',
        'presence_hc',
        'writing_offer',
        'applications_open',
        'ats',
        'ats_auto',
        'multicasting',
        'analytics',
        'applications_spontaneous',
        'testimonials',
        'addresses_shown',
        'questionnaires',
        'spotlight_vdc_company',
        'spotlight_vdc_offer',
        'cvtheque',
        'daily_check',
        'reporting',
        'profiles_sending',
        'cho',
        'commit'
      ]
      // TODO find better way
      if (this.item.name === 'address') r.push('address_added_disclaimer')
      if (this.item.name === 'unlock') r.push('unlock_offer')
      return r
    },
    cptContext () {
      const isCurrent = this.showCurrent && this.isSubscribed() === this.item.name
      const isRecommended = this.showRecommended && this.item.fields.recommended
      return {
        current: isCurrent ? `bd-2 bd-${this.item.fields.color}` : '',
        recommended: isRecommended ? `bd-2 bd-${this.item.fields.color}` : '',
        badge: isCurrent ? 'current' : isRecommended ? 'recommended' : false
      }
    },
    computedFeaturesList () {
      const itemFeatures = this.$_.get(this.item, 'fields.features', [])
      return this.allFeatures.map(f => {
        const hasAsString = itemFeatures.includes(f)
        const hasAsObject = itemFeatures.find(pf => pf.title === f || (Array.isArray(f) && f.includes(pf.title)))
        if (Array.isArray(f)) {
          const includedArrayFeature = f.find(item => itemFeatures.some(pf => (pf.title || pf) === item))
          return { title: includedArrayFeature || f[0], included: !!includedArrayFeature, ...(hasAsObject || {}) }
        } else {
          const r = { title: f, included: hasAsString || !!hasAsObject, ...(hasAsObject || {}) }
          if (r.subtitleOverride) {
            r.title = r.subtitle ?? r.title
            delete r.subtitle
          }
          return r
        }
      }).filter((f) => this.compact ? f.included && !this.$_.get(f, 'hideOnInclude', false) : true)
    },
  },
  methods: {
    selectItem (item) {
      if (this.$_.get(this.item, 'metadata.hidePrice', false)) {
        this.askDemo()
        return
      }
      let loc = null
      if (process.env.HC_PLATFORM === 'WEB') loc = getCurrentUserId() ? this.$app({ name: 'shop', query: { order: [{ productId: this.$_.get(item, 'fields.product.id', null) }] } }) : `${process.env.HC_APP_URL}/?tab=signup&role=client&redirect=${base64Encoder({ name: 'shop', query: { order: [{ productId: this.$_.get(item, 'fields.product.id', null) }] } })}`
      else if (process.env.HC_PLATFORM === 'APP' && this.$route.name === 'shop') this.$emit('selectItem', item)
      else if (process.env.HC_PLATFORM === 'APP') {
        // Mainly subscription page
        this.$router.push({ name: 'shop' })
      } else loc = this.$app()
      if (loc) window.open(loc)
    },
    askDemo () {
      const objDiv = document.getElementById('calendarFrame')
      if (objDiv) objDiv.scrollIntoView({ behavior: 'smooth' })
      else window.open(this.$web({ name: 'contact' }), '_blank')
    },
  }
}
</script>
